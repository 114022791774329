<template>
  <div class="pa-lg" style="max-width: 500px; margin: auto">
    <h5>Status dos serviços</h5>
    <div class="m-t">
      <table class="tbl-services" style="width: 100%">
        <tr>
          <td>API</td>
          <td :class="status.api.ok === false ? 'falha' : 'ok'">
            {{ status.api.statusString }}
          </td>
        </tr>
        <tr>
          <td>Websocket</td>
          <td :class="status.websocket.ok === false ? 'falha' : 'ok'">
            {{ status.websocket.statusString }}
          </td>
        </tr>
        <tr>
          <td>Selfie no cadastro</td>
          <td :class="status.selfie.ok === false ? 'falha' : 'ok'">
            {{ status.selfie.statusString }}
          </td>
        </tr>
        <tr>
          <td>Biometria</td>
          <td :class="status['biometria-facial'].ok === false ? 'falha' : 'ok'">
            {{ status['biometria-facial'].statusString }}
          </td>
        </tr>
        <tr>
          <td>SMS</td>
          <td :class="status.sms.ok === false ? 'falha' : 'ok'">
            {{ status.sms.statusString }}
          </td>
        </tr>
      </table>
      <div class="m-t-lg">
        <a href="/#/">Voltar</a>
      </div>
    </div>
  </div>
</template>

<script>

import {pingApi, servicos} from '@/domain/global'

export default {
  name: 'ServiceStatus',
  inject: ['appRoot'],
  data () {
    return {
      status: {
        api: {
          ok: null,
          statusString: '.',
          error: null
        },
        websocket: {
          ok: null,
          statusString: '.',
          error: null
        },
        selfie: {
          ok: null,
          statusString: '.',
          error: null
        },
        'biometria-facial': {
          ok: null,
          statusString: '.',
          error: null
        },
        sms: {
          ok: null,
          statusString: '.',
          error: null
        }
      }
    }
  },
  components: {},
  created () {
  },
  mounted () {
    this.iniciaTestes()
  },
  methods: {
    iniciaTestes () {
      this.testApi()
      this.testWebsocket()
      this.testServicos()
    },
    testApi () {
      pingApi()
          .then(() => {
            this.status.api.ok = true
            this.status.api.statusString = 'Ok'
          })
          .catch(() => {
            this.status.api.ok = false
            this.status.api.statusString = 'Falha'
          })
    },
    testWebsocket () {
      !this.appRoot.comunicator && this.appRoot.connectComunicator()
      setTimeout(() => {
        if (this.appRoot.websocketStatus === 'online') {
          this.status.websocket.ok = true
          this.status.websocket.statusString = 'Conectado'
        } else {
          this.status.websocket.ok = false
          this.status.websocket.statusString = 'Falha'
        }
      }, 2000)
    },
    testServicos () {
      servicos()
          .then((response) => {
            const s = ['selfie', 'biometria-facial', 'sms']
            let listaServicos = []
            if (response.data && response.data.servicos) {
              listaServicos = response.data.servicos
            }
            s.map(servico => {
              const find = listaServicos.find(f => f === servico)
              if (find) {
                this.status[servico].ok = true
                this.status[servico].statusString = 'Disponível'
              } else {
                this.status[servico].ok = false
                this.status[servico].statusString = 'Indisponível'
              }
            })
          })
          .catch(() => {
            this.status.api.ok = false
            this.status.api.statusString = 'Falha'
          })
    }
  },
  meta () {
    return {
      title: 'Service Status'
    }
  }
}
</script>

<style lang="stylus" scoped>
.ok {
  color green !important
}

.falha {
  color darkred !important
}
.tbl-services td:first-child {
  width: 60%;
}

.tbl-services td:last-child {
  width: 40%;
}
</style>
